<template>
  <h2 class="font-zeyada text-4xl my-4" v-if="products.length === 0 && recipes.length === 0">No hay resultados para "{{ q }}"</h2>

  <div :class="products.length > 0 ? 'mb-20' : ''" v-if="products.length > 0">
    <h2 class="font-zeyada text-4xl my-4">Productos con "{{ q }}"</h2>

    <div
      class="
        w-12
        h-12
        mx-auto
        my-10
        border-2 border-yellow-500
        rounded-full
        loader
      "
      v-if="!areProductsLoaded"
    ></div>

    <TransitionRoot
      :show="areProductsLoaded"
      enter="transition-opacity duration-500"
      enter-from="opacity-0"
      enter-to="opacity-100"
    >
      <ul
        class="
          space-y-12
          sm:grid sm:grid-cols-2
          sm:gap-x-6 sm:gap-y-12
          sm:space-y-0
          lg:grid-cols-3
          lg:gap-x-8
        "
        v-if="areProductsLoaded"
      >
        <li v-for="product in products" :key="product.slug">
          <router-link
            :to="{ name: 'Producto', params: { slug: product.slug } }"
          >
            <div class="space-y-4">
              <div class="aspect-w-4 aspect-h-3">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="API_BASE_URL + product.foto.url"
                  :alt="product.titulo"
                />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3 class="text-center text-gray-600 text-sm">
                    {{ product.titulo }}
                  </h3>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </TransitionRoot>
  </div>

  <div v-if="recipes.length > 0">
    <h2 class="font-zeyada text-4xl my-4">Recetas con "{{ q }}"</h2>

    <div
      class="
        w-12
        h-12
        mx-auto
        my-10
        border-2 border-yellow-500
        rounded-full
        loader
      "
      v-if="!areRecipesLoaded"
    ></div>

    <TransitionRoot
      :show="areRecipesLoaded"
      enter="transition-opacity duration-500"
      enter-from="opacity-0"
      enter-to="opacity-100"
    >
      <ul
        class="
          space-y-12
          sm:grid sm:grid-cols-2
          sm:gap-x-6 sm:gap-y-12
          sm:space-y-0
          lg:grid-cols-3
          lg:gap-x-8
        "
        v-if="areRecipesLoaded"
      >
        <li v-for="recipe in recipes" :key="recipe.slug">
          <router-link :to="{ name: 'Receta', params: { slug: recipe.slug } }">
            <div class="space-y-4">
              <div class="aspect-w-4 aspect-h-3">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="API_BASE_URL + recipe.foto.url"
                  :alt="recipe.titulo"
                />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3 class="text-center text-gray-600 text-sm">
                    {{ recipe.titulo }}
                  </h3>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref, onUpdated, watch } from "vue";
import { useRouter } from "vue-router";
import { TransitionRoot } from "@headlessui/vue";
import { API_BASE_URL } from "@/config/parameters";

export default {
  name: "search",
  components: { TransitionRoot },
  setup() {
    const products = ref([]);
    const recipes = ref([]);
    const areProductsLoaded = ref(false);
    const areRecipesLoaded = ref(false);
    const router = useRouter();
    const q = ref(router.currentRoute.value.query.q);

    watch(q, (newValue) => {
      searchProducts(newValue);
      searchRecipes(newValue);
    });

    const searchProducts = (query) => {
      areProductsLoaded.value = false;
      products.value = [];

      fetch(
        `${API_BASE_URL}/productos?_limit=1000&_start=0&_sort=updated_at:DESC&_publicationState=live&titulo_contains=${query}`
      )
        .then((response) => response.json())
        .then((data) => {
          products.value.push(...data);
          areProductsLoaded.value = true;
        })
        .catch((error) => console.error(error));
    };

    const searchRecipes = (query) => {
      areRecipesLoaded.value = false;
      recipes.value = [];

      fetch(
        `${API_BASE_URL}/recetas?_limit=1000&_start=0&_sort=updated_at:DESC&_publicationState=live&titulo_contains=${query}`
      )
        .then((response) => response.json())
        .then((data) => {
          recipes.value.push(...data);
          areRecipesLoaded.value = true;
        })
        .catch((error) => console.error(error));
    };

    onUpdated(() => {
      q.value = router.currentRoute.value?.query?.q;
    });

    q.value = router.currentRoute.value?.query?.q;
    searchProducts(q.value);
    searchRecipes(q.value);

    return {
      API_BASE_URL,
      products,
      recipes,
      areProductsLoaded,
      areRecipesLoaded,
      q,
    };
  },
};
</script>
